import { useMemo, ChangeEventHandler } from "react";
import ImageChanger from "../../components/image-changer/image-changer";
import { Config } from "../../api/config";

interface ContentData {
  [key: string]: string;
}

interface PanoramicViewImageProps {
  imageName: string;
  marginRight: boolean;
  marginLeft: boolean;
  name: string;
  edit: boolean;
  onChange: ChangeEventHandler;
}

function PanoramicViewImage({
  imageName,
  marginLeft,
  marginRight,
  name,
  edit,

  onChange,
}: PanoramicViewImageProps) {
  const width = useMemo(() => {
    if (marginLeft && marginRight) {
      return "60%";
    } else if (!marginLeft && !marginRight) {
      return "100%";
    } else {
      return "80%";
    }
  }, [marginLeft, marginRight]);

  console.log(imageName);
  return (
    <div
      style={{
        //backgroundImage: imageName,
        backgroundImage: `url(${Config.imageBaseURL}${imageName})`,
        // backgroundColor: color,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundSize: "cover",
        backgroundPosition: "50% 50%",
        marginBottom: "25px",
        marginLeft: marginLeft ? "20%" : 0,
        marginRight: marginRight ? "20%" : 0,
        width: width,
        height: "100%",
        aspectRatio: "4/2", //4/2
      }}
    >
      {edit && (
        <ImageChanger
          pageFileName="panoramicView"
          oldImageName={imageName}
          name={name}
          onChange={onChange}
        />
      )}
    </div>
  );
}

export default PanoramicViewImage;
