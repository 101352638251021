import { Box, Grid, Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { ChangeEvent, useEffect, useState } from "react";
import Actions from "../../store/actions";
import Api from "../../api";
import { createPanoramicViewObjectFromArray } from "../../assets/helpers/content-data";
import PanoramicViewImage from "./PanoramicViewImage";
import { PanoramicViewHeader } from "./PanoramicViewHeader";
import LoadingModal from "../../components/modals/loading-modal/loading-modal";
import ConfirmModal from "../../components/modals/confirm-modal/confirm-modal";
import PrimaryButton from "../../components/bottons/primary-button";
import ImageChanger from "../../components/image-changer/image-changer";
import { EditableText } from "./EditableText";

interface ContentData {
  [key: string]: string;
}

function PanoramicView() {
  const dispatch = useDispatch();

  const contentSession = useSelector((state: any) => state.contentSession);
  const userSession = useSelector((state: any) => state.userSession);

  const [edit, setEdit] = useState(false);
  const [save, setSave] = useState(false);

  const fileName = "PanoramicView";

  const [panoramicViewData, setPanoramicViewData] =
    useState<ContentData | null>(null);

  const handleEnableEditing = () => {
    setEdit(true);
    console.log("setovao sam edit na true");
  };

  const handleSaveChanges = () => {
    setSave(true);
  };

  const handleConfirmSaveChanges = () => {
    setEdit(false);
    setSave(false);
    SetPanoramicViewPage();
  };

  const SetPanoramicViewPage = async () => {
    dispatch(Actions.startLoadingContentSession());
    if (panoramicViewData !== null) {
      await Api.setPanoramicViewPage(Object.values(panoramicViewData))
        .then((response) => {
          dispatch(
            Actions.setPanoramicViewContent(
              createPanoramicViewObjectFromArray(response.data)
            )
          );

          dispatch(Actions.endLoadingContentSession(""));
        })
        .catch((err) => {
          console.log(err);
          dispatch(Actions.endLoadingContentSession("ERROR"));
        });
    }
  };

  const inputChangeHandler = () => {
    return ({
      target: { name, value },
    }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (panoramicViewData !== null)
        setPanoramicViewData((prev: ContentData | null) => ({
          ...prev,
          [name]: value,
        }));
    };
  };

  useEffect(() => {
    if (panoramicViewData === null && contentSession.panoramicView !== null) {
      setPanoramicViewData(contentSession.panoramicView);
    }
  }, [contentSession]);

  return (
    <>
      <LoadingModal open={contentSession.loading} />
      <ConfirmModal
        open={save}
        handelNo={() => {
          setSave(false);
        }}
        handleConfirm={handleConfirmSaveChanges}
      />

      {panoramicViewData !== null && (
        <div>
          {userSession.data !== "" && (
            <div
              style={{
                position: "fixed",
                top: "10px",
                right: "10px",
                zIndex: "9999",
                visibility: save ? "hidden" : "visible",
              }}
            >
              {edit ? (
                <PrimaryButton onClick={handleSaveChanges}>
                  Save changes
                </PrimaryButton>
              ) : (
                <PrimaryButton onClick={handleEnableEditing}>
                  Enable editing
                </PrimaryButton>
              )}
            </div>
          )}

          <PanoramicViewHeader backgroundImage={panoramicViewData.headerImage}>
            <EditableText
              edit={edit}
              value={panoramicViewData.headerTitle}
              name="title1"
              onChange={inputChangeHandler()}
              variant="h3"
              style={{ fontWeight: "bold", color: "white" }}
              textAreaOrInput="input"
            />

            {edit && (
              <div
                style={{ position: "absolute", bottom: "10px", right: "10px" }}
              >
                <ImageChanger
                  pageFileName={fileName}
                  oldImageName={panoramicViewData.headerImage}
                  name="headerImage"
                  onChange={inputChangeHandler()}
                />
              </div>
            )}
          </PanoramicViewHeader>
          <Grid
            container
            sx={{
              alignContent: "center",
              justifyContent: "center",
              flexWrap: "wrap-reverse",
            }}
          >
            <Grid
              item
              xs={10}
              md={8}
              xl={4}
              lg={5}
              sx={{
                margin: {
                  xs: "20px auto auto auto",
                  lg: "100px 0px 120px 0px",
                },
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <PanoramicViewImage
                imageName={panoramicViewData.image1}
                marginLeft={false}
                marginRight={true}
                name="image1"
                edit={edit}
                onChange={inputChangeHandler()}/>

              <PanoramicViewImage
                imageName={panoramicViewData.image2}
                marginLeft={true}
                marginRight={false}
                name="image2"
                edit={edit}
                onChange={inputChangeHandler()}
              />
              <PanoramicViewImage
                imageName={panoramicViewData.image3}
                marginLeft={false}
                marginRight={true}
                name="image3"
                edit={edit}
                onChange={inputChangeHandler()}
              />
              <PanoramicViewImage
                imageName={panoramicViewData.image4}
                marginLeft={true}
                marginRight={false}
                name="image4"
                edit={edit}
                onChange={inputChangeHandler()}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={8}
              xl={3}
              lg={3}
              sx={{ margin: { xs: "20px auto", lg: "150px 0px 150px 50px" } }}
            >
              <EditableText
                edit={edit}
                value={panoramicViewData.articleTitle}
                name="articleTitle"
                onChange={inputChangeHandler()}
                variant="h3"
                textAreaOrInput="text"
              />

              <EditableText
                edit={edit}
                value={panoramicViewData.text1}
                name="text1"
                onChange={inputChangeHandler()}
                variant="body1"
                textAreaOrInput="textarea"
                style={{ marginTop: "50px" }}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}

export default PanoramicView;
