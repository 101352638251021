import React from "react";
import { Box, IconButton, Modal, Paper, Typography } from "@mui/material";
import DiscountBanner from "../assets/images/DreamOpening20.jpg";
import { useState } from "react";
import { ColorButton } from "./app-bar/app-bar";
import { MainThemeColor } from "../models/constants/Colors";
import CloseIcon from "@mui/icons-material/Close";

const DREAMOPENING20Url =
  "https://booking.roomraccoon.com/nikiti-dream/en/?coupon=DREAMOPENING20";

export function DiscountPopup() {
  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "9999",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: { xs: "90%", sm: "600px" },
          height: { xs: "auto", sm: "auto" },
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          outline: "none",
          overflow: "hidden",
          border: 5,
          borderColor: MainThemeColor,
        }}
      >
        <img
          src={DiscountBanner}
          alt=""
          style={{
            width: "100%",
            height: "100%",
          }}
        />

        <IconButton
          onClick={() => {
            setOpen(false);
          }}
          sx={{ position: "absolute", top: 0, right: 0, padding: "10px" }}
        >
          <CloseIcon />
        </IconButton>

        <div
          style={{
            position: "absolute",
            bottom: 0,
            padding: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <a
            href={DREAMOPENING20Url}
            target="_blank"
            rel="noopener noreferrer"
            className="app-bar-link"
          >
            <ColorButton variant="contained">Book now</ColorButton>
          </a>
        </div>
      </Box>
    </Modal>
  );
}
