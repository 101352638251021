import React, { ChangeEvent, useState, useEffect } from "react";
import { Alert, Box, Grid, Snackbar, Typography } from "@mui/material";
import HouseCard from "../../components/cards/house-card/house-card";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PrimaryButton from "../../components/bottons/primary-button";
import ImageChanger from "../../components/image-changer/image-changer";
import { Config } from "../../api/config";
import Actions from "../../store/actions";
import Api from "../../api";
import LoadingModal from "../../components/modals/loading-modal/loading-modal";
import { createHomeObjectFromArray } from "../../assets/helpers/content-data";
import ConfirmModal from "../../components/modals/confirm-modal/confirm-modal";
import { House } from "../../models/House";
import { StripeIssuingCardCvcDisplayElement } from "@stripe/stripe-js";

interface ContentData {
  [key: string]: string;
}

function Home() {
  const dispatch = useDispatch();

  const houseSession = useSelector((state: any) => state.houseSession);
  const contentSession = useSelector((state: any) => state.contentSession);
  const userSession = useSelector((state: any) => state.userSession);

  const [contentData, setContentData] = useState<ContentData | null>(null);

  const fileName = "Home";
  const [edit, setEdit] = useState(false);
  const [save, setSave] = useState(false);

  const inputChangeHandler = () => {
    return ({
      target: { name, value },
    }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (contentData !== null)
        setContentData((prev: ContentData | null) => ({
          ...prev,
          [name]: value,
        }));
    };
  };

  const handleEnableEditing = () => {
    setEdit(true);
  };

  const handleSaveChanges = () => {
    setEdit(false);
    setSave(false);
    SetHomePage();
  };

  const SetHomePage = async () => {
    dispatch(Actions.startLoadingContentSession());
    if (contentData !== null)
      await Api.setHomePage(Object.values(contentData))
        .then((response) => {
          dispatch(
            Actions.setHomeContent(createHomeObjectFromArray(response.data))
          );
          dispatch(Actions.endLoadingContentSession(""));
        })
        .catch((err) => {
          console.log(err);
          dispatch(Actions.endLoadingContentSession("ERROR"));
          /*if(err.message === 'Network Error')
                      navigate('/error')*/
        });
  };

  useEffect(() => {
    if (contentData === null && contentSession.home !== null)
      setContentData(contentSession.home);
  }, [contentSession]);

  return (
    <>
      <LoadingModal open={contentSession.loading} />
      <ConfirmModal
        open={save}
        handelNo={() => {
          setSave(false);
        }}
        handleConfirm={handleSaveChanges}
      />
      {contentData !== null ? (
        <>
          {userSession.data !== "" && (
            <Grid
              sx={{
                position: "fixed",
                top: "10px",
                right: "10px",
                zIndex: "9999",
                visibility: save ? "hidden" : "visible",
              }}
            >
              {!edit ? (
                <PrimaryButton onClick={handleEnableEditing}>
                  Enable editing
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  onClick={() => {
                    setSave(true);
                  }}
                >
                  Save changes
                </PrimaryButton>
              )}
            </Grid>
          )}
          <Box
            sx={{
              backgroundImage: `url(${Config.imageBaseURL}${contentData.headerImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              minHeight: "100vh",
              color: "white",
              fontWeight: "100",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!edit ? (
              // transparent line
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#009dff50",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "64px",
                  marginBottom: "64px",
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    textShadow: "2px -2px 2px #011d7a",
                    letterSpacing: "0.15em",
                    fontWeight: "bold",
                    fontSize: {
                      xs: "64px",
                      sm: "74x",
                      md: "78px",
                      lg: "96px",
                    },
                  }}
                >
                  {contentData.headerTitle}
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    textShadow: "2px -2px 2px #011d7a",
                    letterSpacing: "0.1em",
                    fontWeight: "bold",
                    marginTop: "30px",
                    width: { xs: "85%", sm: "80%", md: "70%", lg: "70%" },
                    fontSize: {
                      xs: "18px",
                      sm: "20px",
                      md: "22px",
                      lg: "36px",
                    },
                  }}
                >
                  {contentData.headerText}
                </Typography>
              </div>
            ) : (
              <>
                <input
                  className="admin-input"
                  type="text"
                  name="headerTitle"
                  value={contentData.headerTitle}
                  onChange={inputChangeHandler()}
                ></input>
                <br />
                <input
                  className="admin-input"
                  type="text"
                  name="headerText"
                  value={contentData.headerText}
                  onChange={inputChangeHandler()}
                ></input>
                <div
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                  }}
                >
                  <ImageChanger
                    pageFileName={fileName}
                    oldImageName={contentData.headerImage}
                    name="headerImage"
                    onChange={inputChangeHandler()}
                  />
                </div>
              </>
            )}
          </Box>
          <Grid
            container
            sx={{ alignContent: "center", justifyContent: "center" }}
          >
            <Grid
              item
              xs={10}
              md={8}
              xl={3}
              lg={3}
              sx={{ margin: { xs: "20px auto", lg: "150px 50px 150px 0px" } }}
            >
              {!edit ? (
                <>
                  <Typography variant="h3">{contentData.promoTitle}</Typography>
                  <Typography variant="body1" sx={{ marginTop: "50px" }}>
                    {contentData.promoText}
                  </Typography>
                </>
              ) : (
                <>
                  <input
                    className="admin-input"
                    type="text"
                    name="promoTitle"
                    value={contentData.promoTitle}
                    onChange={inputChangeHandler()}
                  ></input>
                  <br />
                  <textarea
                    className="admin-textarea"
                    name="promoText"
                    value={contentData.promoText}
                    onChange={inputChangeHandler()}
                  />
                </>
              )}
            </Grid>
            <Grid
              item
              xs={10}
              md={8}
              xl={4}
              lg={5}
              sx={{
                margin: { xs: "20px auto", lg: "100px 0px 120px 0px" },
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${Config.imageBaseURL}${contentData.promoImage1})`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundSize: "cover",
                  backgroundPosition: "50% 50%",
                  marginBottom: "50px",
                  width: "48%",
                  aspectRatio: "3/4",
                }}
              >
                {edit && (
                  <ImageChanger
                    pageFileName={fileName}
                    oldImageName={contentData.promoImage1}
                    name="promoImage1"
                    onChange={inputChangeHandler()}
                  />
                )}
              </div>
              <div
                style={{
                  backgroundImage: `url(${Config.imageBaseURL}${contentData.promoImage2})`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundSize: "cover",
                  backgroundPosition: "50% 50%",
                  marginTop: "50px",
                  width: "48%",
                  aspectRatio: "3/4",
                }}
              >
                {edit && (
                  <ImageChanger
                    pageFileName={fileName}
                    oldImageName={contentData.promoImage2}
                    name="promoImage2"
                    onChange={inputChangeHandler()}
                  />
                )}
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {!edit ? (
              <>
                {/* ongoing offers */}
                <Typography variant="h3">{contentData.promoTitle2}</Typography>
              </>
            ) : (
              <>
                <input
                  className="admin-input"
                  name="promoTitle2"
                  type="text"
                  value={contentData.promoTitle2}
                  onChange={inputChangeHandler()}
                ></input>
              </>
            )}
          </Grid>
          <Grid
            container
            sx={{
              justifyContent: "center",
              marginTop: "50px",
              paddingBottom: "50px",
            }}
          >
           
            {houseSession.allHouses.map(
              (house: House, index: number) => (
                console.log("Jedna kuca koja je povucena iz store-a", house), // ovde se uzima hose sa backa koja  ima 7 8 liste i koja se koristi u houseCard
                (
                  //dispatch(Actions.postaviIdKuce(index)),
                  <HouseCard
                    loading={houseSession.loading}
                    key={index}
                    data={house}
                    
                  />
                )
              )
            )}
          </Grid>
          <Grid
            container
            sx={{
              alignContent: "center",
              justifyContent: "center",
              flexWrap: "wrap-reverse",
            }}
          >
            <Grid
              item
              xs={10}
              md={8}
              xl={4}
              lg={5}
              sx={{
                margin: { xs: "20px auto", lg: "100px 0px 120px 0px" },
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${Config.imageBaseURL}${contentData.accommodationImage1})`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundSize: "cover",
                  backgroundPosition: "50% 50%",
                  marginBottom: "50px",
                  width: "48%",
                  aspectRatio: "3/4",
                }}
              >
                {edit && (
                  <ImageChanger
                    pageFileName={fileName}
                    oldImageName={contentData.accommodationImage1}
                    name="accommodationImage1"
                    onChange={inputChangeHandler()}
                  />
                )}
              </div>
              <div
                style={{
                  backgroundImage: `url(${Config.imageBaseURL}${contentData.accommodationImage2})`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundSize: "cover",
                  backgroundPosition: "50% 50%",
                  marginTop: "50px",
                  width: "48%",
                  aspectRatio: "3/4",
                }}
              >
                {edit && (
                  <ImageChanger
                    pageFileName={fileName}
                    oldImageName={contentData.accommodationImage2}
                    name="accommodationImage2"
                    onChange={inputChangeHandler()}
                  />
                )}
              </div>
            </Grid>
            <Grid
              item
              xs={10}
              md={8}
              xl={3}
              lg={3}
              sx={{ margin: { xs: "20px auto", lg: "150px 0px 150px 50px" } }}
            >
              {!edit ? (
                <>
                  <Link
                    to="/accommodation"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Typography variant="h3">
                      {contentData.accommodationTitle}
                    </Typography>
                  </Link>
                  <Typography variant="body1" sx={{ marginTop: "50px" }}>
                    {contentData.accommodationText}
                    <Link style={{ color: "black" }} to={"/accommodation"}>
                      page
                    </Link>
                  </Typography>
                </>
              ) : (
                <>
                  <input
                    className="admin-input"
                    name="accommodationTitle"
                    type="text"
                    value={contentData.accommodationTitle}
                    onChange={inputChangeHandler()}
                  ></input>
                  <br />
                  <textarea
                    className="admin-textarea"
                    name="accommodationText"
                    value={contentData.accommodationText}
                    onChange={inputChangeHandler()}
                  />
                </>
              )}
            </Grid>
          </Grid>

          <Grid container>
            {/* Activities and experiences */}
            <Grid
              id="feature-item-1"
              sx={{
                backgroundImage: `url(${Config.imageBaseURL}${contentData.feature1Image})`,
              }}
              className="feature-item"
              item
            >
              <div className="feature-item-child-1">
                {!edit ? (
                  <>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        margin: { xs: "20px", md: "50px" },
                      }}
                    >
                      {contentData.feature1Title}
                    </Typography>
                    <div>
                      <Typography
                        variant="body1"
                        sx={{ margin: { xs: "0px 20px", md: "0px 50px" } }}
                      >
                        {contentData.feature1Text} Check details{" "}
                        <Link
                          style={{ color: "white", fontWeight: "bold" }}
                          to={"/experiences"}
                        >
                          here
                        </Link>
                        .
                      </Typography>
                    </div>
                  </>
                ) : (
                  <>
                    <input
                      className="admin-input"
                      type="text"
                      name="feature1Title"
                      value={contentData.feature1Title}
                      onChange={inputChangeHandler()}
                    />
                    <br />
                    <textarea
                      className="admin-textarea"
                      name="feature1Text"
                      value={contentData.feature1Text}
                      onChange={inputChangeHandler()}
                    />
                  </>
                )}
              </div>
              {edit && (
                <div
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                  }}
                >
                  <ImageChanger
                    pageFileName={fileName}
                    oldImageName={contentData.feature1Image}
                    name="feature1Image"
                    onChange={inputChangeHandler()}
                  />
                </div>
              )}
            </Grid>
            {/* Panoramic view */}
            <Grid
              id="feature-item-2"
              sx={{
                backgroundImage: `url(${Config.imageBaseURL}${contentData.feature2Image})`,
              }}
              className="feature-item"
              item
            >
              <div className="feature-item-child-1">
                {!edit ? (
                  <>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        margin: { xs: "20px", md: "50px" },
                      }}
                    >
                      {contentData.feature2Title}
                    </Typography>
                    <div>
                      <Typography
                        variant="body1"
                        sx={{ margin: { xs: "0px 20px", md: "0px 50px" } }}
                      >
                        {contentData.feature2Text} Check details{" "}
                        <Link
                          style={{ color: "white", fontWeight: "bold" }}
                          to={"/panoramic view"}
                        >
                          here
                        </Link>
                        .
                      </Typography>
                    </div>
                  </>
                ) : (
                  <>
                    <input
                      className="admin-input"
                      type="text"
                      name="feature2Title"
                      value={contentData.feature2Title}
                      onChange={inputChangeHandler()}
                    />
                    <br />
                    <textarea
                      className="admin-textarea"
                      name="feature2Text"
                      value={contentData.feature2Text}
                      onChange={inputChangeHandler()}
                    />
                  </>
                )}
              </div>
              {edit && (
                <div
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                  }}
                >
                  <ImageChanger
                    pageFileName={fileName}
                    oldImageName={contentData.feature2Image}
                    name="feature2Image"
                    onChange={inputChangeHandler()}
                  />
                </div>
              )}
            </Grid>
            {/* Private pools */}
            <Grid
              id="feature-item-3"
              sx={{
                backgroundImage: `url(${Config.imageBaseURL}${contentData.feature3Image})`,
              }}
              className="feature-item"
              item
            >
              <div className="feature-item-child-1">
                {!edit ? (
                  <>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        margin: { xs: "20px", md: "50px" },
                      }}
                    >
                      {contentData.feature3Title}
                    </Typography>
                    <div>
                      <Typography
                        variant="body1"
                        sx={{ margin: { xs: "0px 20px", md: "0px 50px" } }}
                      >
                        {contentData.feature3Text}
                        {/* Check details{" "} */}
                        {/* <Link
                          style={{ color: "white", fontWeight: "bold" }}
                          to={"/experiences"}
                        >
                          here
                        </Link> */}
                        {/* . */}
                      </Typography>
                    </div>
                  </>
                ) : (
                  <>
                    <input
                      className="admin-input"
                      type="text"
                      name="feature3Title"
                      value={contentData.feature3Title}
                      onChange={inputChangeHandler()}
                    />
                    <br />
                    <textarea
                      className="admin-textarea"
                      name="feature3Text"
                      value={contentData.feature3Text}
                      onChange={inputChangeHandler()}
                    />
                  </>
                )}
              </div>
              {edit && (
                <div
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                  }}
                >
                  <ImageChanger
                    pageFileName={fileName}
                    oldImageName={contentData.feature3Image}
                    name="feature3Image"
                    onChange={inputChangeHandler()}
                  />
                </div>
              )}
            </Grid>
            {/* Facilities */}
            <Grid
              id="feature-item-4"
              sx={{
                backgroundImage: `url(${Config.imageBaseURL}${contentData.feature4Image})`,
              }}
              className="feature-item"
              item
            >
              <div className="feature-item-child-1">
                {!edit ? (
                  <>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        margin: { xs: "20px", md: "50px" },
                      }}
                    >
                      {contentData.feature4Title}
                    </Typography>
                    <div>
                      <Typography
                        variant="body1"
                        sx={{ margin: { xs: "0px 20px", md: "0px 50px" } }}
                      >
                        {contentData.feature4Text} Check details{" "}
                        <Link
                          style={{ color: "white", fontWeight: "bold" }}
                          to={"/facilities"}
                        >
                          here
                        </Link>
                        .
                      </Typography>
                    </div>
                  </>
                ) : (
                  <>
                    <input
                      className="admin-input"
                      type="text"
                      name="feature4Title"
                      value={contentData.feature4Title}
                      onChange={inputChangeHandler()}
                    />
                    <br />
                    <textarea
                      className="admin-textarea"
                      name="feature4Text"
                      value={contentData.feature4Text}
                      onChange={inputChangeHandler()}
                    />
                  </>
                )}
              </div>
              {edit && (
                <div
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                  }}
                >
                  <ImageChanger
                    pageFileName={fileName}
                    oldImageName={contentData.feature4Image}
                    name="feature4Image"
                    onChange={inputChangeHandler()}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            container
            sx={{ height: "100vh", backgroundColor: "white" }}
          ></Grid>
        </>
      )}
    </>
  );
}

export default Home;
