import { createSlice } from '@reduxjs/toolkit'
import { House } from '../../models/House'
import { UnavailableAppointment } from '../../models/UnavailableAppointment'
import { isEqualDates } from '../../assets/helpers/conpare-dates'

interface ContentData {
    [key: string]: string;
}

export const contentSlice = createSlice({
    name: 'house',
    initialState: {
        home: null as ContentData | null,
        accommodation: null as ContentData | null,
        experiences: null as ContentData | null,
        facilities: null as ContentData | null,
        contact: null as ContentData | null,
        panoramicView:null as ContentData | null,
        loading: true,
        message: '',
        error: false
    },
    reducers: {
        startLoadingContentSession: (state) => {
            return { ...state, loading: true }
        },
        endLoadingContentSession: (state, action) => {
            return { ...state, loading: false, message: action.payload }
        },
        setHomeContent: (state, action) => {
            return { ...state, home: action.payload }
        },
        setAccommodationContent: (state, action) => {
            return { ...state, accommodation: action.payload }
        },
        setExperiencesContent: (state, action) => {
            return { ...state, experiences: action.payload }
        },
        setFacilitiesContent: (state, action) => {
            return { ...state, facilities: action.payload }
        },
        setContactContent: (state, action) => {
            return { ...state, contact: action.payload }
        },
        setPanoramicViewContent:(state,action)=>
        {
            return { ...state, panoramicView:action.payload}
        }
    },
})

export const {startLoadingContentSession, endLoadingContentSession, setHomeContent, setAccommodationContent, setExperiencesContent, setContactContent, setFacilitiesContent, setPanoramicViewContent} = contentSlice.actions

export default contentSlice.reducer