import { useEffect, useState, useCallback } from "react";
import { Navigate, Routes, useNavigate } from "react-router-dom";
import { Route } from "react-router-dom";
import "./App.css";
import ApplicationBar, { ColorButton } from "./components/app-bar/app-bar";
import Footer from "./components/footer/footer";
import Accommodation from "./containers/accommodation/accommodation";
import Contact from "./containers/contact/contact";
import Experiences from "./containers/experiences/experiences";
import Facilities from "./containers/facilities/facilities";
import Home from "./containers/home/home";
import Success from "./containers/success/success";
import Actions from "./store/actions";
import { useDispatch, useSelector } from "react-redux";
import ErrorPage from "./containers/error-page/error-page";
import { useLocation } from "react-router-dom";
import Api from "./api";
import Gallery from "./containers/gallery/gallery";
import Details from "./containers/details/details";
import AdminApplicationBar from "./components/app-bar/admin-app-bar/app-bar";
import {
  createContactObjectFromArray,
  createExperiencesObjectFromArray,
  createFacilitiesObjectFromArray,
  createHomeObjectFromArray,
  createPanoramicViewObjectFromArray,
} from "./assets/helpers/content-data";
import CreateHouse from "./containers/create-house/create-house";
import LogIn from "./containers/login/login";
import { useCookies } from "react-cookie";
import LoadingModal from "./components/modals/loading-modal/loading-modal";
import { TermsAndConditions } from "./containers/terms-and-conditions/TermsAndConditions";
import { PrivacyPolicy } from "./containers/privacy-policy/PrivacyPolicy";
import PanoramicView from "./containers/panoramic-view/PanoramicView";
import {
  Box,
  Modal,
  Paper,
  ThemeProvider,
  Typography,
  appBarClasses,
} from "@mui/material";
import { DiscountPopup } from "./components/DiscountPopup";

function checkDateIsAfterJuly(): boolean {
  const currentDate = new Date();
  const firstJuly = new Date(currentDate.getFullYear(), 6, 1);
  const isAfterFirstJuly = currentDate.getTime() > firstJuly.getTime();
  return !isAfterFirstJuly;
}

function App() {
  const dispatch = useDispatch();
  const userSession = useSelector((state: any) => state.userSession);
  const reservationSession = useSelector(
    (state: any) => state.reservationSession
  );
  const navigate = useNavigate();
  const location = useLocation();

  const [condition, setCondition] = useState<boolean>(true);
  const [cookies] = useCookies(["ndToken"]);

  const showDiscountPopup: boolean = checkDateIsAfterJuly();

  const GetHousesCallback = useCallback(() => {
    setCondition(false);
    const GetHouses = async () => {
      dispatch(Actions.startLoadingHouseSession());
      await Api.getHouses()
        .then((response) => {
          //console.log(response.data)
          dispatch(Actions.setAllHouses(response.data));
          dispatch(Actions.endLoadingHouseSession(""));
        })
        .catch((err) => {
          console.log(err);
          dispatch(Actions.setAllHouses([]));
          dispatch(Actions.endLoadingHouseSession("ERROR"));
          if (err.message === "Network Error") navigate("/error");
        });
    };
    GetHouses();
  }, [dispatch, navigate]);

  const GetContentsCallback = useCallback(() => {
    setCondition(false);
    const GetContents = async () => {
      dispatch(Actions.startLoadingContentSession());
      await Api.getContent()
        .then((response) => {
         // console.log(response.data);
          dispatch(
            Actions.setHomeContent(
              createHomeObjectFromArray(response.data.home)
            )
          );
          dispatch(
            Actions.setAccommodationContent(
              createHomeObjectFromArray(response.data.accommodation)
            )
          );
          dispatch(
            Actions.setExperiencesContent(
              createExperiencesObjectFromArray(response.data.experiences)
            )
          );
          dispatch(
            Actions.setContactContent(
              createContactObjectFromArray(response.data.contact)
            )
          );
          dispatch(
            Actions.setFacilitiesContent(
              createFacilitiesObjectFromArray(response.data.facilities)
            )
          );
          dispatch(
            Actions.setPanoramicViewContent(
              createPanoramicViewObjectFromArray(response.data.panoramicViews)
            )
          );

          dispatch(Actions.endLoadingContentSession(""));
        })
        .catch((err) => {
          console.log(err);
          dispatch(Actions.endLoadingContentSession("ERROR"));
          if (err.message === "Network Error") navigate("/error");
        });
    };
    GetContents();
  }, [dispatch, navigate]);

  const GetGalleryCallback = useCallback(() => {
    const GetGalleryImages = async () => {
      dispatch(Actions.startLoadingGallerySession());
      await Api.getGalleryImages()
        .then((response) => {
          //console.log(response.data)
          dispatch(Actions.setImages(response.data));
          dispatch(Actions.endLoadingGallerySession(""));
        })
        .catch((err) => {
          console.log(err);
          dispatch(Actions.endLoadingGallerySession("ERROR"));
        });
    };
    GetGalleryImages();
  }, [dispatch]);

  const GetUserCallback = useCallback(() => {
    const GetUser = async () => {
      dispatch(Actions.startLoadingUserSession());
      await Api.getGalleryImages()
        .then((response) => {
          dispatch(Actions.setUserData(response.data));
          dispatch(Actions.endLoadingUserSession(""));
        })
        .catch((err) => {
          console.log(err);
          dispatch(Actions.endLoadingUserSession("ERROR"));
        });
    };
    GetUser();
  }, [dispatch]);

  const GetBeachesCallback = useCallback(async () => {
    dispatch(Actions.startLoadingBeachSession());
    await Api.getBeaches()
      .then((response) => {
        // console.log(response.data)
        dispatch(Actions.setBeaches(response.data));
        dispatch(Actions.endLoadingBeachSession(""));
      })
      .catch((err) => {
        console.log(err);
        dispatch(Actions.endLoadingBeachSession("ERROR"));
      });
  }, [dispatch, navigate]);

  useEffect(() => {
    if (condition) {
      GetHousesCallback();
      GetContentsCallback();
      GetGalleryCallback();
      GetBeachesCallback();
    }
  }, [condition, GetHousesCallback]);

  useEffect(() => {
    var element = document.getElementById("reservation-steps");
    if (element !== null && element !== undefined) {
      window.scrollTo({
        top: element.offsetTop - 100,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [reservationSession.reservationStep]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location.pathname]);

  useEffect(() => {
    if (cookies.ndToken !== null && cookies.ndToken !== undefined) {
      GetUserCallback();
    }
  }, [cookies]);

  return (
    <>
      <LoadingModal open={userSession.loading} />
      {location.pathname !== "/login" &&
        location.pathname !== "/error" &&
        (userSession.data !== "" ? (
          <AdminApplicationBar />
        ) : (
          <ApplicationBar />
        ))}
      {/* popup sa roomraccoon discount linkom */}
      {showDiscountPopup && <DiscountPopup />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/accommodation" element={<Accommodation />} />
        <Route path="/facilities" element={<Facilities />} />
        <Route path="/experiences" element={<Experiences />} />
        <Route path="/contact us" element={<Contact />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/success" element={<Success />} />
        <Route path="/details/:name" element={<Details />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/create house" element={<CreateHouse />} />
        <Route path="/login" element={<LogIn />} />
        <Route path="/panoramic view" element={<PanoramicView />} />
        <Route path="*" element={<Navigate to="/error" />} />
      </Routes>
      {location.pathname !== "/error" && location.pathname !== "/login" && (
        <Footer />
      )}
    </>
  );
}

export default App;
