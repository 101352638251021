import {
  startLoadingHouseSession,
  endLoadingHouseSession,
  setAllHouses,
  addUnavailableAppointments,
  addUnavailableAppointmentsForAllHouses,
  addMonthsWithUnavailableAppointments,
  clearHouseSession,
  swapImages,
} from "./slices/houseSlice";

import {
  startLoadingReservationSession,
  endLoadingReservationSession,
  startLoadingReservationSteps,
  endLoadingReservationSteps,
  setReservationStep,
  setHouse,
  setPrice,
  setArrivalDate,
  setDepartureDate,
  setNumberOfNights,
  setNumberOfPersons,
  setAdditions,
  clearReservationSession,
} from "./slices/reservationSlice";

import {
  startLoadingContentSession,
  endLoadingContentSession,
  setHomeContent,
  setAccommodationContent,
  setExperiencesContent,
  setFacilitiesContent,
  setContactContent,
  setPanoramicViewContent,
} from "./slices/contentSlice";

import {
  startLoadingAmenitySession,
  endLoadingAmenitySession,
  setAmenities,
} from "./slices/amenitySlice";

import {
  startLoadingGallerySession,
  endLoadingGallerySession,
  setImages,
  moveImage,
} from "./slices/gallerySlice";

import {
  startLoadingUserSession,
  endLoadingUserSession,
  setUserData,
} from "./slices/userSlice";

import {
  startLoadingBeachSession,
  endLoadingBeachSession,
  setBeaches,
} from "./slices/beachSlice";



const Actions = {
  startLoadingHouseSession,
  endLoadingHouseSession,
  setAllHouses,
  addUnavailableAppointments,
  addUnavailableAppointmentsForAllHouses,
  addMonthsWithUnavailableAppointments,
  clearHouseSession,
   swapImages,

  startLoadingReservationSession,
  endLoadingReservationSession,
  startLoadingReservationSteps,
  endLoadingReservationSteps,
  setReservationStep,
  setHouse,
  setArrivalDate,
  setDepartureDate,
  setNumberOfNights,
  setNumberOfPersons,
  setPrice,
  setAdditions,
  clearReservationSession,

  startLoadingContentSession,
  endLoadingContentSession,
  setHomeContent,
  setAccommodationContent,
  setExperiencesContent,
  setFacilitiesContent,
  setContactContent,
  setPanoramicViewContent,

  startLoadingAmenitySession,
  endLoadingAmenitySession,
  setAmenities,

  startLoadingGallerySession,
  endLoadingGallerySession,
  setImages,
  moveImage,

  startLoadingUserSession,
  endLoadingUserSession,
  setUserData,

  startLoadingBeachSession,
  endLoadingBeachSession,
  setBeaches,

 
};

export default Actions;
