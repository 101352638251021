import axios from "axios";
import {
  useState,
  ChangeEventHandler,
  ChangeEvent,
  useRef,
  MouseEvent,
} from "react";
import ConfirmModal from "../modals/confirm-modal/confirm-modal";
import { ChangeCircle } from "@mui/icons-material";
import Api from "../../api";
import { useDispatch } from "react-redux";
import Actions from "../../store/actions";
import PrimaryButton from "../bottons/primary-button";
import { Image } from "../../models/Image";

interface Props {
  setImages: Function;
  houseId: number;
  callback?: Function;
}

export default function HouseImageUploader({
  setImages,
  houseId,
  callback,
}: Props) {
  const dispatch = useDispatch();
  const [imageFile, setImageFile] = useState<any>(null);
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);

  const handleClick = () => {
    if (hiddenFileInput !== null && hiddenFileInput.current !== null)
      hiddenFileInput.current.click();
  };

  const handleImageUpload = async () => {
    dispatch(Actions.startLoadingHouseSession());
    await Api.addImage({
      imageFile: imageFile,
      houseId: houseId,
    })
      .then((response) => {
        setImages((perv: Image[]) => [...perv, response.data]);
        setImageFile(null);
        callback !== undefined && callback();
        dispatch(Actions.endLoadingHouseSession(""));
      })
      .catch((error) => {
        console.log(error);
        dispatch(Actions.endLoadingHouseSession("ERROR"));
      });
  };

  const handleNo = () => {
    setImageFile(null);
  };

  return (
    <>
      <ConfirmModal
        open={imageFile !== null}
        handleConfirm={handleImageUpload}
        handelNo={handleNo}
      />
      <input
        ref={hiddenFileInput}
        style={{ overflow: "hidden", width: "0px", height: "0px" }}
        type="file"
        onChange={(e) => {
          e.target.files !== null &&
            e.target.files.length > 0 &&
            setImageFile(e.target.files[0]);
        }}
      />
      <PrimaryButton onClick={handleClick}>Add Image</PrimaryButton>
    </>
  );
}
