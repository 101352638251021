import React from "react";
import Typography from "@mui/material/Typography";
import { CSSObject, TypographyVariant } from "@mui/material";

interface EditableText {
  edit: boolean;
  value: string;
  name: string;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  variant: TypographyVariant;
  style?: CSSObject;
  textAreaOrInput: string;
}

export function EditableText({
  edit,
  value,
  name,
  onChange,
  variant,
  style,
  textAreaOrInput,
}: EditableText) {
  return edit ? (
    textAreaOrInput === "textarea" ? (
      <textarea
        style={{
          maxWidth: "500px",
          width: "90%",
          maxHeight: "300px",
          minHeight: "150px",
          borderRadius: "5px",
          border: "1px dashed #009DFF",
        }}
        name={name}
        value={value}
        onChange={onChange}
      />
    ) : (
      <input
        className="admin-input"
        type="text"
        name={name}
        value={value}
        onChange={onChange}
      />
    )
  ) : (
    <Typography variant={variant} sx={style}>
      {value}
    </Typography>
  );
}
