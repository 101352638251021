import { BoxProps } from "@mui/material";
import { Config } from "../../api/config";

interface PanoramicViewHeaderProps extends BoxProps {
  backgroundImage: string;
}

export function PanoramicViewHeader({
  backgroundImage,
  children,
}: PanoramicViewHeaderProps) {
  return (
    <div
      style={{
        backgroundSize: "100% 100%",
        backgroundPosition: "50% 50%",
        height: "500px",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${Config.imageBaseURL}${backgroundImage})`,
      }}
    >
      {children}
    </div>
  );
}
