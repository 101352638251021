import { createSlice } from "@reduxjs/toolkit";

export const gallerySlice = createSlice({
  name: "gallery",
  initialState: {
    images: [] as string[],
    loading: false,
    message: "",
    error: false,
  },
  reducers: {
    startLoadingGallerySession: (state) => {
      return { ...state, loading: true };
    },
    endLoadingGallerySession: (state, action) => {
      return { ...state, loading: false, message: action.payload };
    },
    setImages: (state, action) => {
      return { ...state, images: action.payload };
    },
    moveImage: (state, action) => {
      const { index, newIndex } = action.payload;
      if (newIndex >= 0 && newIndex < state.images.length) {
        const newImages = [...state.images];
        const temp = newImages[newIndex];
        newImages[newIndex] = newImages[index];
        newImages[index] = temp;
        return { ...state, images: newImages };
      }
      return state;
    },
  },
});

export const {
  startLoadingGallerySession,
  endLoadingGallerySession,
  setImages,
  moveImage,
} = gallerySlice.actions;

export default gallerySlice.reducer;
