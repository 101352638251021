import { Grid, useMediaQuery } from "@mui/material";
import { Config } from "../../../api/config";
import { Delete } from "@mui/icons-material";
import Actions from "../../../store/actions";
import Api from "../../../api";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../../modals/confirm-modal/confirm-modal";
import { useEffect, useMemo, useState } from "react";
import { Image } from "../../../models/Image";

import ArrowCircleLeftSharpIcon from "@mui/icons-material/ArrowCircleLeftSharp";
import ArrowCircleRightSharpIcon from "@mui/icons-material/ArrowCircleRightSharp";
import ArrowCircleDownSharpIcon from "@mui/icons-material/ArrowCircleDownSharp";
import ArrowCircleUpSharpIcon from "@mui/icons-material/ArrowCircleUpSharp";
import { House } from "../../../models/House";
import { useTheme } from "@emotion/react";

interface Props {
  image: any;
  setImages: Function;
  deleteCallback?: Function;
  index?: number;
  HouseId?: number;
}

export default function ImageCard({
  image,
  setImages,
  deleteCallback,
  index,
  HouseId,
}: Props) {
  const dispatch = useDispatch();
  const VillaImagesSlice = useSelector(
    (state: any) => state.villaImagesSession
  );
  const houseSession = useSelector((state: any) => state.houseSession);

  const theme = useTheme();
  const greaterThanMid = useMediaQuery("(min-width:960px)");

  const offset = useMemo(() => {
    if (greaterThanMid) return 3;
    else return 1;
  }, [greaterThanMid]);

  const showArrow = (newIndex: number) => {
    if (
      HouseId !== undefined &&
      newIndex >= 0 &&
      newIndex < houseSession.allHouses[HouseId].images.length
    ) {
      return true;
    }
    return false;
  };

  const ImageIndex = useMemo(() => {
    if (index !== undefined) {
      return index;
    } else return -1;
  }, [index]);

  const [imageId, setImageId] = useState(null);

  const handleDeleteImage = async () => {
    dispatch(Actions.startLoadingHouseSession());
    await Api.deleteImage(imageId)
      .then((response) => {
        setImages((perv: Image[]) => [
          ...perv.filter((img) => img.id !== imageId),
        ]);
        setImageId(null);
        deleteCallback !== undefined && deleteCallback();
        dispatch(Actions.endLoadingHouseSession(""));
      })
      .catch((error) => {
        console.log(error);
        dispatch(Actions.endLoadingHouseSession("ERROR"));
      });
  };

  const handleNo = () => {
    setImageId(null);
  };

  const swapElements = (firstIndex: number, secondIndex: number) => {
    console.log("Indeksi su ", firstIndex, secondIndex);

    if (HouseId != undefined) {
      dispatch(Actions.swapImages({ firstIndex, secondIndex, HouseId }));
    }
  };

  return (
    <>
      <ConfirmModal
        open={imageId !== null}
        handelNo={handleNo}
        handleConfirm={handleDeleteImage}
      />
      <Grid
        xs={11}
        md={3}
        sx={{
          backgroundImage: `url(${Config.houseImageBaseURL}${image.smallImageName})`,
          position: "relative",
          aspectRatio: "1",
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
          margin: "20px",
        }}
      >
        {greaterThanMid && (
          <>
            <ArrowCircleLeftSharpIcon
              onClick={() => {
                swapElements(ImageIndex, ImageIndex - 1);
              }}
              style={{
                fontSize: "40px",
                position: "absolute",
                top: "50%",
                transform: "translate(0,-50%)",
                left: "5px",
                cursor: "pointer",
                visibility: showArrow(ImageIndex - 1) ? "visible" : "hidden",
              }}
            />

            <ArrowCircleRightSharpIcon
              onClick={() => {
                swapElements(ImageIndex, ImageIndex + 1);
              }}
              style={{
                fontSize: "40px",
                position: "absolute",
                top: "50%",
                transform: "translate(0,-50%)",
                right: "5px",
                cursor: "pointer",
                visibility: showArrow(ImageIndex + 1) ? "visible" : "hidden",
              }}
            />
          </>
        )}

        <ArrowCircleDownSharpIcon
          onClick={() => {
            swapElements(ImageIndex, ImageIndex + offset);
          }}
          style={{
            fontSize: "40px",
            position: "absolute",
            bottom: "5px",
            left: "50%",
            transform: "translateX(-50%)",
            textAlign: "center",
            cursor: "pointer",
            visibility: showArrow(ImageIndex + offset) ? "visible" : "hidden",
          }}
        />

        <ArrowCircleUpSharpIcon
          onClick={() => {
            swapElements(ImageIndex, ImageIndex - offset);
          }}
          style={{
            fontSize: "40px",
            position: "absolute",
            top: 0,
            left: "50%",
            transform: "translateX(-50%)",
            textAlign: "center",
            cursor: "pointer",
            visibility: showArrow(ImageIndex - offset) ? "visible" : "hidden",
          }}
        />

        <Delete
          onClick={() => {
            setImageId(image.id);
          }}
          sx={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            color: "white",
            border: "1px solid white",
            padding: "10px",
            backgroundColor: "rgba(0,0,0,.6)",
            cursor: "pointer",
          }}
        />
      </Grid>
    </>
  );
}
