import axios from "axios";
import { ImageRouts } from "../routes";
import { Config } from "../config";

export const addImage = async (data: any) => {
  try {
    const formData = new FormData();
    formData.append("imageFile", data.imageFile);
    formData.append("houseId", data.houseId);
    return await axios.post(
      `${Config.baseURL}${ImageRouts.CREATE_IMAGE}`,
      formData,
      {
        withCredentials: true,
      }
    );
  } catch (error) {
    throw new Error("Error rised while creating image...");
  }
};

export const deleteImage = async (imageId: any) => {
  try {
    return await axios.post(
      `${Config.baseURL}${ImageRouts.DELETE_IMAGE}${imageId}`
    );
  } catch (error) {
    throw new Error("Error rised while creating image...");
  }
};

export const updatePositionOfImages = async (imagesIds: number[]) => {
  try {
    await axios.post(
      `${Config.baseURL}${ImageRouts.UPDATE_POSITIONS_IMAGES}`,
      imagesIds
    );
  } catch (error) {
    throw new Error("Error rised while updating positions...");
  }
};
