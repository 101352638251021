import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TikTokIcon from "../../assets/images/tiktoktransparent.png";
import { TikTokUrl } from "../../models/constants/Links";

interface ContentData {
  [key: string]: string;
}

function Footer() {
  const contentSession = useSelector((state: any) => state.contentSession);

  const [contentData, setContentData] = useState<ContentData | null>(null);

  useEffect(() => {
    if (contentSession.contact !== null) setContentData(contentSession.contact);
  }, [contentSession.contact]);

  return (
    <>
      <Grid
        container
        sx={{
          backgroundColor: "black",
          justifyContent: "center",
          color: "white",
          paddingBottom: "50px",
        }}
      >
        <Grid item xs={10} md={2} sx={{ margin: "10px" }}>
          <Typography
            variant="h5"
            sx={{
              margin: { xs: "50px 0px 20px 0px", md: "120px 0px 50px 0px" },
            }}
          >
            Address
          </Typography>
          <Typography variant="body1">Nikiti, Greece</Typography>
          <Typography variant="body1">
            lat: 40.212793, lng: 23.686640 (next to Geranion Village hotel)
          </Typography>

          <Typography
            variant="body1"
            sx={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <a
              style={{ color: "white", textDecoration: "none" }}
              href={`https://www.google.com/maps/place/40%C2%B012'46.1%22N+23%C2%B041'11.9%22E/@40.2127928,23.6840655,17z/data=!3m1!4b1!4m4!3m3!8m2!3d40.2127928!4d23.6866404?hl=en-NL&entry=ttu`}
              target="_blank"
            >
              Get Direction
            </a>
          </Typography>

          <Typography variant="body1">
            <Link
              style={{ color: "white", textDecoration: "none" }}
              to={`/termsandconditions`}
            >
              Terms and conditions
            </Link>
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "20px" }}>
            <Link
              style={{ color: "white", textDecoration: "none" }}
              to={`/privacypolicy`}
            >
              Privacy policy
            </Link>
          </Typography>

          {contentData !== null && (
            <>
              <a
                href={contentData.instagram}
                target="_blank"
                rel="noopener noreferrer"
                className="app-bar-link"
              >
                <InstagramIcon fontSize="large" sx={{ color: "#8f9195" }} />
              </a>
              <a
                href={contentData.facebook}
                target="_blank"
                rel="noopener noreferrer"
                className="app-bar-link"
              >
                <FacebookIcon fontSize="large" sx={{ color: "#8f9195" }} />
              </a>
              <a
                href={TikTokUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="app-bar-link"
              >
                <img
                  style={{
                    height: "32px",
                    width: "32px",
                    filter: `grayscale(1)`,
                  }}
                  src={TikTokIcon}
                  alt=""
                />
              </a>
            </>
          )}
        </Grid>
        <Grid item xs={10} md={2} sx={{ margin: "10px" }}>
          <Typography
            variant="h5"
            sx={{
              margin: { xs: "50px 0px 20px 0px", md: "120px 0px 50px 0px" },
            }}
          >
            Reservation
          </Typography>
          {contentData !== null && (
            <>
              <Typography variant="body1">{contentData.phone}</Typography>
              <Typography variant="body1">{contentData.email}</Typography>
            </>
          )}
        </Grid>
        <Grid item xs={10} md={2} sx={{ margin: "10px" }}>
          <Typography
            variant="h5"
            sx={{
              margin: { xs: "50px 0px 20px 0px", md: "120px 0px 50px 0px" },
            }}
          >
            Navigation
          </Typography>
          <Typography variant="body1">
            <Link
              style={{ color: "white", textDecoration: "none" }}
              to="/accommodation"
            >
              Accommodation
            </Link>
          </Typography>
          <Typography variant="body1">
            <Link
              style={{ color: "white", textDecoration: "none" }}
              to="/facilities"
            >
              Facilities
            </Link>
          </Typography>
          <Typography variant="body1">
            <Link
              style={{ color: "white", textDecoration: "none" }}
              to="/panoramic view"
            >
              Panoramic View
            </Link>
          </Typography>
          <Typography variant="body1">
            <Link
              style={{ color: "white", textDecoration: "none" }}
              to="/experiences"
            >
              Experience
            </Link>
          </Typography>
          <Typography variant="body1">
            <Link
              style={{ color: "white", textDecoration: "none" }}
              to="/gallery"
            >
              Gallery
            </Link>
          </Typography>
          <Typography variant="body1">
            <Link
              style={{ color: "white", textDecoration: "none" }}
              to="/contact us"
            >
              Contact Us
            </Link>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          backgroundColor: "black",
          justifyContent: "center",
          color: "white",
        }}
      >
        <Grid
          item
          xs={10}
          md={6}
          sx={{ borderTop: "1px solid white", padding: "25px 0px" }}
        >
          <Typography variant="body1">
            Copyright ©2022-2024 All rights reserved
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default Footer;
